<template>
  <b-col class="col-12 col-md-8 pt-5 px-3 p_b__3">
    <h4 class="mb-4 font-weight-normal text-muted">
      {{ $t("page.billing.title") }}
    </h4>
    <b-card no-body class="p-3 mb-3">
      <h6 class="font-weight-bold mb-3">
        {{ $t("page.billing.billingInformation") }}
      </h6>
      <div class="body__2 font-weight-bold text-muted">
        {{ userCompanyName }}
      </div>
      <div class="body__2 text-muted">
        {{ userAddress }}
      </div>
      <div class="body__2 text-muted">
        {{ userCountry }}
      </div>
      <div class="body__2 text-muted">
        {{ userLocationInfo }}
      </div>
      <b-col class="col-auto px-0 mt-3">
        <b-button size="sm" variant="outline-light" @click="goToAccountPage">
          <img
            src="./../assets/general-icons/pencil-alt-2.svg"
            class="action__icon clickable mr-2"
          />
          <span class="body__2 text-muted">{{
            $t("page.billing.editMyInformation")
          }}</span>
        </b-button>
      </b-col>
      <hr class="my-5" />
      <h6 class="font-weight-bold">{{ $t("page.billing.myInvoices") }}</h6>
      <p class="subtitle__1 text-muted">
        {{ $t("page.billing.invoiceMessage") }}
      </p>
      <div class="mt-3">
        <div v-if="isInvoiceListEmpty" class="font-italic text-uppercase">
          You have not got any invoice yet.
        </div>
        <div v-else>
          <div v-for="(invoice, index) in invoiceList" :key="index">
            <invoice-list-item
              :invoice="invoice"
              :branch="branchDetails(invoice)"
            />
          </div>
        </div>
      </div>
    </b-card>
  </b-col>
</template>

<script>
import InvoiceListItem from "./../components/Billing/InvoiceListItem";
export default {
  components: {
    InvoiceListItem,
  },
  computed: {
    invoiceList() {
      return this.$store.getters["billing/getInvoiceList"];
    },
    isInvoiceListEmpty() {
      return this.invoiceList.length === 0;
    },
    userAccount() {
      return this.$store.getters["auth/getUserAccount"];
    },
    userCompanyName() {
      return this.userAccount.companyName || "-";
    },
    userAddress() {
      return this.userAccount.address || "-";
    },
    userCountry() {
      return this.userAccount.country || "-";
    },
    userLocationInfo() {
      if (
        this.userAccount.zip &&
        this.userAccount.city &&
        this.userAccount.province
      )
        return `${this.userAccount.zip} ${this.userAccount.city} - ${this.userAccount.province}`;
      else return "-";
    },
    isClientMultiUser() {
      return this.$store.getters["auth/isMultiUser"];
    },
  },
  methods: {
    goToAccountPage() {
      this.$router.push({ name: "Account" });
    },
    branchDetails(invoice) {
      if (this.isClientMultiUser) {
        const branches = this.$store.getters["multiuser/getBranches"];
        return branches.find((branch) => {
          return invoice.branchId === branch._id;
        });
      }
    },
  },
};
</script>

<style scoped>
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
