<template>
  <div>
    <b-row class="no-gutters align-items-center">
      <b-col class="col-12 col-lg-3 body__1">
        {{ invoiceNumber }}
      </b-col>
      <b-col class="col-12 col-lg-2 text-muted body__2 text-lg-right">
        {{ invoicePrice | price }} &euro;
      </b-col>
      <b-col class="col-12 col-lg-2 text-muted body__2 text-lg-right">
        {{ $t("page.billing.totalVideos", { total: invoiceItemsPurchased }) }}
      </b-col>
      <b-col
        class="col-12 col-lg-2 text-muted body__2 text-lg-right"
        v-if="branch"
      >
        {{ branchName }}
        <span class="text-lowercase">
          {{ $t("branch") }}
        </span>
      </b-col>
      <b-col class="col-auto body__2 ml-auto">
        <b-row class="no-gutters">
          <b-col class="col-auto mr-1">
            <b-icon
              icon="file-earmark"
              variant="black"
              font-scale="1.0"
            ></b-icon>
          </b-col>
          <b-col class="col-auto mr-1">
            <b-link class="link">
              {{ $t("invoice") }}
            </b-link>
          </b-col>
          <b-col class="col-auto mr-1"
            ><b-icon icon="file-text" variant="black" font-scale="1.0"></b-icon
          ></b-col>
          <b-col class="col-auto">
            <b-link class="link">
              {{ $t("receipt") }}
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr class="my-2" />
  </div>
</template>

<script>
export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    branch: {
      type: Object,
    },
  },
  computed: {
    invoiceNumber() {
      return this.invoice.number;
    },
    invoicePrice() {
      return this.invoice.amount;
    },
    invoiceItemsPurchased() {
      return this.invoice.itemsPurchased;
    },
    branchName() {
      return this.branch.name;
      // return "Crete";
    },
  },
};
</script>

<style scoped>
.body__1 {
  font-size: 14px;
  font-weight: 500;
}
</style>
